import Subscriptions from "./components/subscriptions";
import ProductSearch from "./components/productsearch";
import About from "./components/about";
import React, { useState, useContext } from 'react';
import './App.css';
import Header from './components/shared/header';
import Footer from './components/shared/footer';
//import { Context } from './components/context';
import CarouselComponent from "./components/carousel";
import MarqueeComponent from "./components/marqueecomponent";
import {  Route, Routes } from "react-router-dom";
import PriceDroppedPage from "./components/PriceDroppedPage";




function App() {
  //let emailId = "bbeamerdreamer@gmail.com";
  //const {emailId, setEmailId} = useContext(Context);

  return (
    <div className="App">
      <div>
        <Header></Header>
      </div>
      <div className="logoCaraouselDivMain">
        {/*<CarouselComponent/>*/}
        <MarqueeComponent/>
      </div>
      <div className="middleMain">
        <Routes>
            <Route path="/"  element={<ProductSearch/>} />
            <Route path="/"  element={<Subscriptions/>} />
            <Route path="/productsearch" element={<ProductSearch/>} />
            <Route path="/subscriptions" element={<Subscriptions/>} />
            <Route path="/price-dropped" element={<PriceDroppedPage/>} />
            <Route path="/about" element={<About/>} />
        </Routes>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
