import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Card, CardMedia, CardContent, Typography, makeStyles } from "@material-ui/core";
import { getRetailerLogoForProduct } from "./shared/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  card: {
    width: 345, // Fixed width
    height: 400, // Fixed height
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media: {
    height: 200, // Fixed height for media
  },
  content: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

const PriceDroppedTilesComponent = () => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PRODUCTFETCHER_URL}/api/v1/products/pricedropped`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching price dropped products:", error);
      });
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.catalogueId}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={product.prodImg}
                title={product.prodDescription}
                style={{ objectFit: 'contain' }} // Ensure the image fits within the container
              />
              <CardContent className={classes.content}>
                <Typography gutterBottom variant="h6" component="h2">
                  {product.prodDescription}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Price: ${product.price}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  <a href={product.prodUrl} target="_blank" rel="noopener noreferrer" className={classes.link}>
                    View Product
                  </a>
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {getRetailerLogoForProduct(product.retailerName)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PriceDroppedTilesComponent;