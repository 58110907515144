import React from "react";
import PriceDroppedTiles from "./PriceDroppedTilesComponent";
import { Container, Typography } from "@material-ui/core";

const PriceDroppedPage = () => {
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom style={{ color: '#3f51b5', fontFamily: "Open Sans, sans-serif", fontWeight: 600 }}>
        Products with Recent price drops
      </Typography>
      <PriceDroppedTiles />
    </Container>
  );
};

export default PriceDroppedPage;